import React, {useState,} from 'react';
import logo from '../assets/images/endless-logo.png';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { ADD_TOKEN } from "../redux/actionTypes";
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';
const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const token = useSelector(state => state.networkReducer.token)

    const [showNotification, setShowNotification] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState('');
  
    const showFailedNotification = () => {
      setNotificationStatus('danger');
      setShowNotification(true);
    }
    
    const login = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const requestOptions = {
            method: 'POST',
            body: formData,
        }
        fetch(`${networkConstants.apiDomain}/api-auth/`, requestOptions)
            .then(response => response.json())
            .then(data => [dispatch({ type: 'ADD_TOKEN', payload: data.token}), console.log(data), history.push("/home"), showFailedNotification()])
    }
    
    return (
        <div>
            <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
                <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
            </Toast>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center"><img src={logo} alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>{}</h4>
                                                    <h6>{"Enter your Username and Password"} </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={(e)=>login(e)}>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Username {token}</label>
                                                        <input className="form-control" type="text" name='username' required="" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control" type="password" name='password' required="" />
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="submit">Log in</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Login;