import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const Contacts = () => {
  const [contactsData, setContactsData] = useState({});
  const token = useSelector(state => state.networkReducer.token);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  const getContactsData = () => {
    fetch(`${networkConstants.apiUrl}contacts/`)
    .then(response => response.json())
    .then(data => [setContactsData(data)])
  }

  useEffect(()=>{getContactsData()}, [])

  const sendPageData = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}contacts/`, requestOptions)
        .then(response => response.json())
        .then(data => [getContactsData(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
      <Fragment>
          <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
            <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
          </Toast>
          <Breadcrumb parent="Pages" title="Contacts" />
          <Form onSubmit={sendPageData}>
            <div className="container-fluid">
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Instagramm link</h5>
                  <Form.Control type="url" name='instagram_link' defaultValue={contactsData.instagram_link}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Dribble link</h5>
                  <Form.Control type="url" name='drible_link' defaultValue={contactsData.drible_link}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Twitter link</h5>
                  <Form.Control type="url" name='twitter_link' defaultValue={contactsData.twitter_link}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Youtube link</h5>
                  <Form.Control type="url" name='youtube_link' defaultValue={contactsData.youtube_link}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Email</h5>
                  <Form.Control type="email" name='email' defaultValue={contactsData.email}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Phone</h5>
                  <Form.Control type="tel" name='phone' defaultValue={contactsData.phone}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Adress</h5>
                  <Form.Control type="text" name='adress' defaultValue={contactsData.adress}/>
                </div>
              </div>
              <Button type='submit'>Save</Button>
            </div>
          </Form>  
      </Fragment>
  );
};

export default Contacts;