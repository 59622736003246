import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const PromoutionSettings = () => {
  const [settings, setSettings] = useState({});
  const token = useSelector(state => state.networkReducer.token);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  const getSettings = () => {
    const requestOptions = {
      method: 'GET',
      headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}promoution/settings/`, requestOptions)
    .then(response => response.json())
    .then(data => [setSettings(data)])
  }

  useEffect(()=>{getSettings()}, [])

  const sendSettings = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}promoution/settings/`, requestOptions)
        .then(response => response.json())
        .then(data => [getSettings(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
      <Fragment>
          <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
            <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
          </Toast>
          <Breadcrumb parent="Pages" title="Promoution Settings" />
          <Form onSubmit={sendSettings}>
            <div className="container-fluid">
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Telegram subscribing points</h5>
                  <Form.Control type="number" name='telegram_subscribing_points' defaultValue={settings.telegram_subscribing_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Telegram medium message points</h5>
                  <Form.Control type="number" name='telegram_medium_message_points' defaultValue={settings.telegram_medium_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Telegram big message points</h5>
                  <Form.Control type="number" name='telegram_big_message_points' defaultValue={settings.telegram_big_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Discord subscribing points</h5>
                  <Form.Control type="number" name='discord_subscribing_points' defaultValue={settings.discord_subscribing_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Discord question message points</h5>
                  <Form.Control type="number" name='discord_question_message_points' defaultValue={settings.discord_question_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Discord big message points</h5>
                  <Form.Control type="number" name='discord_big_message_points' defaultValue={settings.discord_big_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Instagram subscribing points</h5>
                  <Form.Control type="number" name='instagram_subscribing_points' defaultValue={settings.instagram_subscribing_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Instagram medium message points</h5>
                  <Form.Control type="number" name='instagram_medium_message_points' defaultValue={settings.instagram_medium_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Instagram big message points</h5>
                  <Form.Control type="number" name='instagram_big_message_points' defaultValue={settings.instagram_big_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Twitter subscribing points</h5>
                  <Form.Control type="number" name='twitter_subscribing_points' defaultValue={settings.twitter_subscribing_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Twitter mention points</h5>
                  <Form.Control type="number" name='twitter_mention_points' defaultValue={settings.twitter_mention_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Twitter medium message points</h5>
                  <Form.Control type="number" name='twitter_medium_message_points' defaultValue={settings.twitter_medium_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Twitter big message points</h5>
                  <Form.Control type="number" name='twitter_big_message_points' defaultValue={settings.twitter_big_message_points}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Invite points</h5>
                  <Form.Control type="number" name='invite_points' defaultValue={settings.invite_points}/>
                </div>
              </div>
              <Button type='submit'>Save</Button>
            </div>
          </Form>  
      </Fragment>
  );
};

export default PromoutionSettings;