import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './i18n';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";

// Import custom Components 
import Default from './components/dashboard/defaultCompo/default';
// import ServerComponent from './components/dashboard/server/server-component';

import MainPage from './pages/MainPage';
import Team from './pages/Team';
import Reborns from './pages/Reborns';
import Rebornbands from './pages/RebornBands';
import Contacts from './pages/Contacts';
import ContentPage from './pages/ContentPage';
import RebornBand from './pages/RebornBand';
import About from './pages/About';
import Dao from './pages/Dao';
import Requests from './pages/Requests';
import Login from './pages/Login';
import Customers from './pages/Customers';
import PromoutionSettings from './pages/PromoutionSettings';
//config data
import configDB from './data/customizer/config'
import Activities from './pages/Activities';

const Root = () => {

    useEffect(() => {
        const abortController = new AbortController();
        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }
    }, []);

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login/`} component={Login} />
                                <App>
                                    {/* dashboard menu */}
                                    <Route path={`${process.env.PUBLIC_URL}/default/`} component={Default} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/main/`} component={MainPage} />
                                    <Route path={`${process.env.PUBLIC_URL}/team/`} component={Team} />
                                    <Route path={`${process.env.PUBLIC_URL}/reborns/`} component={Reborns} />
                                    <Route path={`${process.env.PUBLIC_URL}/rebornbands/`} component={Rebornbands} />
                                    <Route path={`${process.env.PUBLIC_URL}/requests/`} component={Requests} />
                                    <Route path={`${process.env.PUBLIC_URL}/customers/`} component={Customers} />
                                    <Route path={`${process.env.PUBLIC_URL}/points/promoution/settings/`} component={PromoutionSettings} />

                                    <Route path={`${process.env.PUBLIC_URL}/pages/contacts/`} component={Contacts} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/rebornband/`} component={RebornBand} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/about/`} component={About} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/dao/`} component={Dao} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/activities/`} component={Activities} />

                                    <Route path={`${process.env.PUBLIC_URL}/pages/disclaimer/`} render={(props) => <ContentPage title="Disclaimer" apiPath='/disclaimer/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/policy/`} render={(props) => <ContentPage title="Policy" apiPath='/policy/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/terms/`} render={(props) => <ContentPage title="Terms" apiPath='/terms/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/docs/`} render={(props) => <ContentPage title="Docs" apiPath='/documents/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/game/`} render={(props) => <ContentPage title="Game" apiPath='/universef-game/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/universef/`} render={(props) => <ContentPage title="Universef" apiPath='/universef/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/feedback/`} render={(props) => <ContentPage title="Feedback" apiPath='/feedback/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/rebornpunk/`} render={(props) => <ContentPage title="RebornPunk" apiPath='/rebornpunks/' {...props} />} />

                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/promoution/`} render={(props) => <ContentPage title="Promoution Detail" apiPath='/activities/promoution/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/community/`} render={(props) => <ContentPage title="Community Detail" apiPath='/activities/community/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/video/`} render={(props) => <ContentPage title="Video Detail" apiPath='/activities/video/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/invite/`} render={(props) => <ContentPage title="Invite Detail" apiPath='/activities/invite/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/content/`} render={(props) => <ContentPage title="Content Detail" apiPath='/activities/content/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/nft/`} render={(props) => <ContentPage title="NFT Detail" apiPath='/activities/nft/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/tokens/`} render={(props) => <ContentPage title="Token Detail" apiPath='/activities/tokens/' {...props} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/profile/bounty/`} render={(props) => <ContentPage title="Bounty Detail" apiPath='/activities/bounty/' {...props} />} />
                                </App>
                        </Switch>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();