import {
    Anchor,
    Headphones,
    Home,
    Command,
    Image,
    Folder,
    Mail,
    User,
    DollarSign,
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Home', icon: Home, type: 'link', path: '/support-ticket/supportTicket', active: false
    },
    {
        title: 'Pages', icon: Folder, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/pages/main', title: 'Main', type: 'link' },
            { path: '/pages/universef', title: 'UniverseF', type: 'link' },
            { path: '/pages/rebornband', title: 'RebornBand', type: 'link' },
            { path: '/pages/rebornpunk', title: 'Rebornpunk', type: 'link' },
            { path: '/pages/activities', title: 'Activities', type: 'link' },
            { path: '/pages/game', title: 'UniverseF Game', type: 'link' },
            { path: '/pages/dao', title: 'DAO F55', type: 'link' },
            { path: '/pages/about', title: 'About us', type: 'link' },
            { path: '/pages/docs', title: 'Documents', type: 'link' },
            { path: '/pages/feedback', title: 'Feedback', type: 'link' },
            { path: '/pages/terms', title: 'Terms & Conditions', type: 'link' },
            { path: '/pages/policy', title: 'Privacy Policy', type: 'link' },
            { path: '/pages/disclaimer', title: 'Disclaimer', type: 'link' },
            { path: '/pages/contacts', title: 'Contacts', type: 'link' },

            { path: '/pages/profile/promoution', title: 'Activities Promoution', type: 'link' },
            { path: '/pages/profile/community', title: 'Activities Community', type: 'link' },
            { path: '/pages/profile/video', title: 'Activities Video', type: 'link' },
            { path: '/pages/profile/invite', title: 'Activities Invites', type: 'link' },
            { path: '/pages/profile/content', title: 'Activities Content', type: 'link' },
            { path: '/pages/profile/nft', title: 'Activities NFT', type: 'link' },
            { path: '/pages/profile/tokens', title: 'Activities Tokens', type: 'link' },
            { path: '/pages/profile/bounty', title: 'Activities Bounty', type: 'link' },
        ]
    },
    {
        title: 'Points', icon: DollarSign, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/points/promoution/settings/', title: 'Promoution Settings', type: 'link' },
        ]
    },
    {
        title: 'Team', icon: Command, type: 'link', path: '/team/', active: false
    },
    {
        title: 'Reborns', icon: Image, type: 'link', path: '/reborns/', active: false
    },
    {
        title: 'Rebornbands', icon: Image, type: 'link', path: '/rebornbands/', active: false
    },
    {
        title: 'Requests', icon: Mail, type: 'link', path: '/requests/', active: false
    },
    {
        title: 'Customers', icon: User, type: 'link', path: '/customers/', active: false
    },
    {
        path: 'http://support.pixelstrap.com/help-center',
        title: 'Raise Support',
        icon: Headphones,
        type: 'exteral_link',
        active: false
    },
]
