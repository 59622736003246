import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Table from 'react-bootstrap/Table';
import moment from 'moment/moment';
import networkConstants from '../networkConstants';

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const token = useSelector(state => state.networkReducer.token);

  useEffect(()=>{
    getReborns();
  },[])

  const getReborns = (e) => {
    const requestOptions = {
      method: 'GET',
      headers: {Authorization: 'Token ' +  token},
    }
    fetch(`${networkConstants.apiUrl}requests/`, requestOptions)
    .then(response => response.json())
    .then(data => setRequests(data))
  }

  return (
    <Fragment>
      <Breadcrumb title="Requests" />
      <div className="container-fluid">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Comment</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request)=>{
              return <tr>
                <td>1</td>
                <td>{request.name}</td>
                <td>{request.email}</td>
                <td>{request.comment}</td>
                <td>{moment(request.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
              </tr>
            })}
          
          </tbody>
        </Table>
      </div>
    </Fragment>
  )

}

export default Requests;