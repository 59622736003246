import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const Customers = () => {
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [editableCustomer, setEditableCustomer] = useState({});
  const handleClose = () => [setShow(false), setEditMode(false)];
  const handleShow = () => setShow(true);
  const token = useSelector(state => state.networkReducer.token);

  useEffect(()=>{
    getCustomers();
  },[])

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  const getCustomers = (e) => {
    const requestOptions = {
      method: 'GET',
      headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}customers/`, requestOptions)
    .then(response => response.json())
    .then(data => { setCustomers(data) })
  }

  const saveCustomer = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}customers/`, requestOptions)
        .then(response => response.json())
        .then(data => [getCustomers(), setShow(false), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  const updateCustomer = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}customers/${editableCustomer.id}/`, requestOptions)
        .then(response => response.json())
        .then(data => [getCustomers(), setShow(false), setEditMode(false), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  const deleteCustomer = (customer) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}customers/${customer.id}/`, requestOptions)
        .then(response => [getCustomers(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
    <Fragment>
      <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
        <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
      </Toast>
      <Breadcrumb title="Customers" />
      <div className="container-fluid">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Wallet</th>
              <th>Points</th>
              <th>Telegram</th>
              <th>Discord</th>
              <th>Twitter</th>
              <th>Instagram</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, id) => {
              return <tr>
                        <td>{id}</td>
                        <td>{customer.email}</td>
                        <td>{customer.wallet}</td>
                        <td>{customer.points}</td>
                        <td>{customer.telegram_nickname}</td>
                        <td>{customer.discord}</td>
                        <td>{customer.twitter}</td>
                        <td>{customer.instagram}</td>
                        {/* <td>
                          <div className='col-4'>
                            <img width={'20%'} src={`http://localhost:8000${member.member_photo}`} alt="" />
                          </div>
                        </td> */}
                        <td style={{display: 'flex', gap: '10px'}}>
                          <Button onClick={()=>[setEditMode(true), setShow(true), setEditableCustomer(customer)]}>Edit</Button>
                          <Button variant='danger' onClick={()=>deleteCustomer(customer)}>Delete</Button>
                        </td>
                      </tr>
            })}
          </tbody>
        </Table>
        <div className='row gy-10 justify-content-start'>
          <div class="col-4 p-3">
            <Button onClick={handleShow}>Add new</Button>
          </div>
        </div>
      </div>


      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={(e) => editMode ? updateCustomer(e) : saveCustomer(e)}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? 'Edit' : 'Add'} new team member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.email : null} name='email' type="text" placeholder="a@mail.ru" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Wallet</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.wallet : null} name='wallet' type="text" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Points</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.points : null} name='points' type="text" placeholder="12312" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.first_name : null} name='first_name' type="text" placeholder="Ivan" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last name</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.last_name : null} name='last_name' type="text" placeholder="Ivanovich" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Telegram Nickname</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.telegram_nickname : null} name='telegram_nickname' type="text" placeholder="@vanya" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Twitter</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.twitter : null} name='twitter' type="text" placeholder="@vanya"/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Instagram</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.instagram : null} name='instagram' type="text" placeholder="@vanya"/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Discord</Form.Label>
                <Form.Control defaultValue={editMode ? editableCustomer.name : null} name='discord' type="text" placeholder="@vanya"/>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </Fragment>
  )

}

export default Customers;