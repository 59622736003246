import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const MainPage = () => {

  const [pageData, setPageData] = useState({});
  const token = useSelector(state => state.networkReducer.token);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  useEffect(()=>{getPageData()}, [])

  const getPageData = () => {
    fetch(`${networkConstants.apiUrl}pages/main/`)
    .then(response => response.json())
    .then(data => [setPageData(data)])
  }

  const sendPageData = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}pages/main/`, requestOptions)
        .then(response => response.json())
        .then(data => [getPageData(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
      <Fragment>
          <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
            <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
          </Toast>
          <Form onSubmit={(e)=>sendPageData(e)}>
            <Breadcrumb parent="Pages" title="MainPage" />
            <div className="container-fluid">
            <Accordion defaultActiveKey={[]}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>SEO</Accordion.Header>
                  <Accordion.Body>
                  <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Title</h5>
                        <input className="form-control" type="text" name='title' defaultValue={pageData.title}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Keywords</h5>
                        <input className="form-control" type="text" name='keywords' defaultValue={pageData.keywords}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Description</h5>
                        <textarea className="form-control" name='description' defaultValue={pageData.description}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Main Banner</Accordion.Header>
                  <Accordion.Body>

                  <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Main Banner green text</h5>
                        <input className="form-control" type="text" name='main_banner_green_text' defaultValue={pageData.main_banner_green_text}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Main Banner Title</h5>
                        <input className="form-control" type="text" name='main_banner_title' defaultValue={pageData.main_banner_title}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Main Banner red text</h5>
                        <input className="form-control" type="text" name='main_banner_red_text' defaultValue={pageData.main_banner_red_text}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Main Banner youtube ID</h5>
                        <input className="form-control" type="text" name='main_banner_youtube_id' defaultValue={pageData.main_banner_youtube_id}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-5 p-3">
                        <h5>Main banner video (1920x1080 mp4)</h5>
                        <input className="form-control" type="file" name='main_banner_video'/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Mint</Accordion.Header>
                  <Accordion.Body>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Mint Title</h5>
                        <input className="form-control" type="text" name='mint_title' defaultValue={pageData.mint_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Mint Date</h5>
                        <input className="form-control" type="text" name='mint_date' defaultValue={pageData.mint_date}/>
                      </div>
                    </div>
                    

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Mint Text</h5>
                        <textarea className="form-control" type="text" name='mint_text' defaultValue={pageData.mint_text}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Reborns</Accordion.Header>
                  <Accordion.Body>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Reborns title</h5>
                        <input className="form-control" type="text" name='reborns_title' defaultValue={pageData.reborns_title}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Universef</Accordion.Header>
                  <Accordion.Body>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef title</h5>
                        <input className="form-control" type="text" name='universef_title' defaultValue={pageData.universef_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef subtitle</h5>
                        <input className="form-control" type="text" name='universef_subtitle' defaultValue={pageData.universef_subtitle}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef first paragraph title</h5>
                        <input className="form-control" type="text" name='universef_first_paragraph_title' defaultValue={pageData.universef_first_paragraph_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef first paragraph text</h5>
                        <textarea className="form-control" type="text" name='universef_first_paragraph_text' defaultValue={pageData.universef_first_paragraph_text}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-5 p-3">
                        <h5>Universef first paragraph first image (940x690 jpeg, png)</h5>
                        <input className="form-control" type="file" name='universef_first_paragraph_first_image'/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-5 p-3">
                        <h5>Universef first paragraph second image (940x690 jpeg, png)</h5>
                        <input className="form-control" type="file" name='universef_first_paragraph_second_image'/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef second paragraph title</h5>
                        <input className="form-control" type="text" name='universef_second_paragraph_title' defaultValue={pageData.universef_second_paragraph_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Universef second paragraph text</h5>
                        <textarea className="form-control" type="text" name='universef_second_paragraph_text' defaultValue={pageData.universef_second_paragraph_text}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-5 p-3">
                        <h5>Universef second paragraph first image (940x690 jpeg, png)</h5>
                        <input className="form-control" type="file" name='universef_second_paragraph_first_image'/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-5 p-3">
                        <h5>Universef second paragraph second image (940x690 jpeg, png)</h5>
                        <input className="form-control" type="file" name='universef_second_paragraph_second_image'/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Release top text</h5>
                        <textarea className="form-control" type="text" name='release_top_text' defaultValue={pageData.release_top_text}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Release wide image (2386x608 jpeg, png)</h5>
                        <input className="form-control" type="file" name='release_wide_image'/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Release bottom text</h5>
                        <textarea className="form-control" type="text" name='release_bottom_text' defaultValue={pageData.release_bottom_text}/>
                      </div>
                    </div>
                    
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Release date</h5>
                        <input className="form-control" type="text" name='release_date' defaultValue={pageData.release_date} />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>NFT Collection</Accordion.Header>
                  <Accordion.Body>
                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>NFT Collection title</h5>
                        <input className="form-control" type="text" name='nft_collection_title' defaultValue={pageData.nft_collection_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Wallet title</h5>
                        <input className="form-control" type="text" name='nft_collection_wallet_title' defaultValue={pageData.nft_collection_wallet_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Wallet text</h5>
                        <textarea className="form-control" type="text" name='nft_collection_wallet_text' defaultValue={pageData.nft_collection_wallet_text}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Marketplace title</h5>
                        <input className="form-control" type="text" name='nft_collection_marketplace_title' defaultValue={pageData.nft_collection_marketplace_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Marketplace text</h5>
                        <textarea className="form-control" type="text" name='nft_collection_marketplace_text' defaultValue={pageData.nft_collection_marketplace_text}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Collect title</h5>
                        <input className="form-control" type="text" name='nft_collection_collect_title' defaultValue={pageData.nft_collection_collect_title}/>
                      </div>
                    </div>

                    <div className='row gy-10 justify-content-start'>
                      <div class="col-4 p-3">
                        <h5>Collect text</h5>
                        <textarea className="form-control" type="text" name='nft_collection_collect_text' defaultValue={pageData.nft_collection_collect_text}/>
                      </div>
                    </div>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
      </Fragment>
  );
};

export default MainPage;