import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const Team = () => {
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [members, setMembers] = useState([]);
  const [editableMember, setEditableMember] = useState({});
  const handleClose = () => [setShow(false), setEditMode(false)];
  const handleShow = () => setShow(true);
  const token = useSelector(state => state.networkReducer.token);

  useEffect(()=>{
    getMembers();
  },[])

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  const getMembers = (e) => {
    fetch(`${networkConstants.apiUrl}team/`)
    .then(response => response.json())
    .then(data => { setMembers(data) })
  }

  const saveMember = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}team/`, requestOptions)
        .then(response => response.json())
        .then(data => [getMembers(), setShow(false), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  const updateMember = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}team/${editableMember.id}/`, requestOptions)
        .then(response => response.json())
        .then(data => [getMembers(), setShow(false), setEditMode(false), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  const deleteMember = (member) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}team/${member.id}/`, requestOptions)
        .then(response => [getMembers(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
    <Fragment>
      <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
        <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
      </Toast>
      <Breadcrumb title="Team" />
      <div className="container-fluid">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Position</th>
              <th>Photo</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, id) => {
              return <tr>
                        <td>{id}</td>
                        <td>{member.name}</td>
                        <td>{member.position}</td>
                        <td>
                          <div className='col-4'>
                            <img width={'20%'} src={`${networkConstants.apiDomain}${member.member_photo}`} alt="" />
                          </div>
                        </td>
                        <td style={{display: 'flex', gap: '10px'}}>
                          <Button onClick={()=>[setEditMode(true), setShow(true), setEditableMember(member)]}>Edit</Button>
                          <Button variant='danger' onClick={()=>deleteMember(member)}>Delete</Button>
                        </td>
                      </tr>
            })}
          </tbody>
        </Table>
        <div className='row gy-10 justify-content-start'>
          <div class="col-4 p-3">
            <Button onClick={handleShow}>Add new</Button>
          </div>
        </div>
      </div>


      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={(e) => editMode ? updateMember(e) : saveMember(e)}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? 'Edit' : 'Add'} new team member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control defaultValue={editMode ? editableMember.name : null} name='name' type="text" placeholder="Ivan Ivanovich" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Position</Form.Label>
                <Form.Control defaultValue={editMode ? editableMember.position : null} name='position' type="text" placeholder="positon" required/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Photo</Form.Label>
                <Form.Control name='photo' type="file" required={editMode ? false : true}/>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </Fragment>
  )

}

export default Team;