import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';
import MultiselectField from './../components/common/multiply-select';

const RebornBand = (props) => {
  const [pageData, setPageData] = useState({});
  const [reborns, setReborns] = useState([]);
  const [selectedReborns, setSelectedReborns] = useState([]);
  const [defaultReborns, setDefaultReborns] = useState(null);
  const token = useSelector(state => state.networkReducer.token);

  useEffect(()=>{getPageData(); getReborns()}, [])

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  const getPageData = () => {
    fetch(`${networkConstants.apiUrl}pages/reborn/`)
    .then(response => response.json())
    .then(data => [setPageData(data), setDefaultReborns(data.reborns)])
  }

  const getReborns = (e) => {
    fetch(`${networkConstants.apiUrl}rebornbands/`)
    .then(response => response.json())
    .then(data => { setReborns(data)})
  }

  const sendPageData = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    formData.append('selected_reborns', selectedReborns.map(choice => choice.value));
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}pages/reborn/`, requestOptions)
        .then(response => response.json())
        .then(data => [getPageData(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }
  return (
      <Fragment>
          <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
            <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
          </Toast>
          <Breadcrumb parent="Pages" title={props.title} />
          <Form onSubmit={sendPageData}>
            <div className="container-fluid">
              <Accordion defaultActiveKey={[]}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>SEO</Accordion.Header>
                  <Accordion.Body>
                  <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Title</h5>
                        <input className="form-control" type="text" name='title' defaultValue={pageData.title}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Keywords</h5>
                        <input className="form-control" type="text" name='keywords' defaultValue={pageData.keywords}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Description</h5>
                        <textarea className="form-control" name='description' defaultValue={pageData.description}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Banner Title</h5>
                  <input className="form-control" name='banner_title' defaultValue={pageData.banner_title}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Banner Text</h5>
                  <textarea className="form-control" name='banner_text' defaultValue={pageData.banner_text}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div class="col-4 p-3">
                  <h5>Banner image (1504x1152 jpeg, png)</h5>
                  <input className="form-control" type="file" name='banner_image'/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Content Title</h5>
                  <input className="form-control" name='content_title' defaultValue={pageData.content_title}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Content Text</h5>
                  <textarea className="form-control" name='content_text' defaultValue={pageData.content_text}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Selected Reborns</h5>
                  {defaultReborns ? 
                    <MultiselectField choices={reborns} defaultChoices={defaultReborns} updateSelectedRebornsInForm={(data)=>{setSelectedReborns(data)}}/>
                  : null}
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
      </Fragment>
  );
};

export default RebornBand;