import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function MultiselectField({...props}) {

  const [choices, setChoices] = useState([]);
  const [defaultChoices, setDefaultChoices] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    if(!props.choices) return false;    

    props.choices.forEach((choice)=>{
      setChoices(choices => [...choices, {value: choice.id, label: `${choice.first_name} ${choice.last_name}`}])
    })
  }, [props.choices])

  useEffect(()=>{
    if(!props.defaultChoices) return false;    
    
    const preparedChoices = [];
    props.defaultChoices.forEach((choice)=>{
      preparedChoices.push({value: choice.id, label: `${choice.first_name} ${choice.last_name}`});
    })

    setDefaultChoices(preparedChoices)
    
  }, [props.defaultChoices])

  useEffect(()=>{
    setLoaded(true);
  }, [defaultChoices])

  const handleOnChange = (value) => {
    props.updateSelectedRebornsInForm(value);
  }

  return (
    <>
      {choices.length > 0 && loaded ? 
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={defaultChoices}
          isMulti
          options={choices}
          onChange={handleOnChange}
        />
      : null}
    </>
  );
}