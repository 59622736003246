import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import Breadcrumb from '../components/common/breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import networkConstants from '../networkConstants';

const About = (props) => {

  const [pageData, setPageData] = useState({});
  const token = useSelector(state => state.networkReducer.token);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState('');

  const showSuccessNotification = () => {
    setNotificationStatus('success');
    setShowNotification(true);
  }

  const showFailedNotification = () => {
    setNotificationStatus('danger');
    setShowNotification(true);
  }

  useEffect(()=>{getPageData()}, [])

  const getPageData = () => {
    fetch(`${networkConstants.apiUrl}pages/about/`)
    .then(response => response.json())
    .then(data => [setPageData(data)])
  }

  const sendPageData = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const requestOptions = {
        method: 'PUT',
        body: formData,
        headers: {Authorization: 'Token ' + token},
    }
    fetch(`${networkConstants.apiUrl}pages/about/`, requestOptions)
        .then(response => response.json())
        .then(data => [getPageData(), showSuccessNotification()])
        .catch(()=>{showFailedNotification()})
  }

  return (
      <Fragment>
          <Toast className='notification' bg={notificationStatus} onClose={() => setShowNotification(false)} show={showNotification} delay={2000} autohide>
            <Toast.Body>{notificationStatus === 'success' ? 'Data saved' : 'Error uploading data'}</Toast.Body>
          </Toast>
          <Breadcrumb parent="Pages" title={props.title} />
          <Form onSubmit={sendPageData}>
            <div className="container-fluid">
              <Accordion defaultActiveKey={[]}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>SEO</Accordion.Header>
                  <Accordion.Body>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Title</h5>
                        <input className="form-control" type="text" name='title' defaultValue={pageData.title}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Keywords</h5>
                        <input className="form-control" type="text" name='keywords' defaultValue={pageData.keywords}/>
                      </div>
                    </div>
                    <div className='row gy-10 justify-content-start'>
                      <div className="col-4 p-3">
                        <h5>Description</h5>
                        <textarea className="form-control" name='description' defaultValue={pageData.description}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Banner title</h5>
                  <input className="form-control" name='label' defaultValue={pageData.label}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Left Pargraph Title</h5>
                  <input className="form-control" name='left_paragraph_title' defaultValue={pageData.left_paragraph_title}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Left Pargraph SubText</h5>
                  <textarea className="form-control" name='left_paragraph_subtext' defaultValue={pageData.left_paragraph_subtext}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Left Pargraph Text</h5>
                  <textarea className="form-control" name='left_paragraph_text' defaultValue={pageData.left_paragraph_text}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Right Pargraph Title</h5>
                  <input className="form-control" name='right_paragraph_title' defaultValue={pageData.right_paragraph_title}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Right Pargraph SubText</h5>
                  <textarea className="form-control" name='right_paragraph_subtext' defaultValue={pageData.right_paragraph_subtext}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <h5>Right Pargraph Text</h5>
                  <textarea className="form-control" name='right_paragraph_text' defaultValue={pageData.right_paragraph_text}/>
                </div>
              </div>
              <div className='row gy-10 justify-content-start'>
                <div className="col-4 p-3">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
      </Fragment>
    );
};

export default About;