const getApiUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000/api/v1/'
  } else {
    return 'https://api.f55.io/api/v1/'
  }  
}

const getApiDomain = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000'
  } else {
    return 'https://api.f55.io'
  }  
}

const networkConstants = {
  apiUrl: getApiUrl(),
  apiDomain: getApiDomain(),
}

export default networkConstants;
