import { ADD_TOKEN } from "../../redux/actionTypes";

const initial_state = {
  token: '',
};

const networkReducer = (state = initial_state, action) => {
    switch (action.type) {

        case ADD_TOKEN:
            return { ...state, token: action.payload };

        default: return { ...state };
    }
}
export default networkReducer